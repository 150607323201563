// import * as authn from './authn';
import * as metadata from './metadata';
// import * as persistor from './persistor';
// import * as triggers from './triggers';

export type Paginated<T> = {
  info: {
    count: number;
    page: number;
    pages: number;
    next: string | null;
    prev: string | null;
  };
  results: T[];
};

export type PaginationQueryWithSearch<T extends string> = {
  page: number;
  perPage: number;
  order?: 'ASC' | 'DESC';
  orderBy?: T;
} & Partial<Record<T, string>>;

export default {
  // authn,
  metadata,
  // persistor,
  // triggers,
};
