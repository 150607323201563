import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import { Dispatch, SetStateAction } from 'react';
import { EntityType, GeomobyPropertiesValues } from '../../../util/enums';
import { jsUcFirst } from '../../Global/StringFormatterFunctions';
import { GeomobyOverride } from '../types';
import {
  findInvalidAlertStyleValue,
  findNonBooleanValue,
  findValueWithMoreThan256Chars,
  findValueWithNonWholeNumber,
} from './Sidebar/Geofence/GeomobyProperties';

export function featureHasUndefinedName(
  features: Feature<Geometry>[],
  type: EntityType.Geofence | EntityType.Microfence,
  setDirtySave: Dispatch<
    SetStateAction<{
      isDirty: boolean;
      issue: string | null;
    }>
  >,
): Feature<Geometry> | undefined {
  const hasUndefinedName = features.find(f => !f.get('name'));
  if (hasUndefinedName !== undefined) {
    setDirtySave({
      isDirty: !!hasUndefinedName,
      issue: hasUndefinedName ? `${jsUcFirst(type)} name is required` : null,
    });
  }
  return hasUndefinedName;
}

export function featureHasDuplicateName(
  features: Feature<Geometry>[],
  type: EntityType.Geofence | EntityType.Microfence,
  setDirtySave: Dispatch<
    SetStateAction<{
      isDirty: boolean;
      issue: string | null;
    }>
  >,
): Feature<Geometry> | undefined {
  const hasDuplicateName = features.find(feature =>
    features.find(
      f =>
        feature.get('name') === f.get('name') &&
        feature.get('id') !== f.get('id') &&
        (f.get('fresh') || f.get('updated')),
    ),
  );
  if (hasDuplicateName !== undefined) {
    setDirtySave({
      isDirty: !!hasDuplicateName,
      issue: hasDuplicateName ? `${jsUcFirst(type)} name must be unique.` : null,
    });
  }
  return hasDuplicateName;
}

export function featureHasEmptyGeomobyPropertyValue(
  features: Feature<Geometry>[],
  setDirtySave: Dispatch<
    SetStateAction<{
      isDirty: boolean;
      issue: string | null;
    }>
  >,
): Feature<Geometry> | undefined {
  const hasEmptyGeomobyPropertyName = features.find(
    f =>
      JSON.stringify(Object.entries(f.get('geomobyProperties')))?.includes('["",') ||
      JSON.stringify(Object.entries(f.get('geomobyProperties')))?.includes(',""'),
  );

  if (hasEmptyGeomobyPropertyName !== undefined) {
    setDirtySave({
      isDirty: !!hasEmptyGeomobyPropertyName,
      issue: hasEmptyGeomobyPropertyName ? 'Geofence Property values must not be left empty' : null,
    });
  }
  return hasEmptyGeomobyPropertyName;
}

export function featureHasIncompleteOverrideRule(
  features: Feature<Geometry>[],
  setDirtySave: Dispatch<
    SetStateAction<{
      isDirty: boolean;
      issue: string | null;
    }>
  >,
): Feature<Geometry> | undefined {
  return features.find(f =>
    f.get('geomobyOverrides')?.find((override: GeomobyOverride) => {
      if (!override.property || !override.value || override.deviceIds?.length === 0) {
        setDirtySave({
          isDirty: true,
          issue: `All Geomoby Override Rules ${
            override.deviceIds?.length === 0 ? 'require device IDs.' : 'fields must be complete.'
          }`,
        });
        return override;
      }
    }),
  );
}

export function featureHasInvalidPropertyOrOverride(
  features: Feature<Geometry>[],
  setDirtySave: Dispatch<SetStateAction<{ isDirty: boolean; issue: string | null }>>,
): Feature<Geometry> | undefined {
  const hasInvalidAlertText = findValueWithMoreThan256Chars(
    features,
    GeomobyPropertiesValues.alertText,
  );
  if (hasInvalidAlertText) {
    setDirtySave({
      isDirty: true,
      issue: `The value of an ${GeomobyPropertiesValues.alertText} property must be less than 256 characters.`,
    });
    return hasInvalidAlertText;
  }

  const hasInvalidAlertTimeOut = findValueWithNonWholeNumber(
    features,
    GeomobyPropertiesValues.alertTimeout,
  );
  const hasInvalidDwellSeconds = findValueWithNonWholeNumber(
    features,
    GeomobyPropertiesValues.dwellSeconds,
  );
  if (hasInvalidAlertTimeOut || hasInvalidDwellSeconds) {
    setDirtySave({
      isDirty: true,
      issue: `The value of ${
        hasInvalidAlertTimeOut
          ? 'an ' + GeomobyPropertiesValues.alertTimeout
          : 'a ' + GeomobyPropertiesValues.dwellSeconds
      } property must a positive whole number.`,
    });
    return hasInvalidAlertTimeOut ?? hasInvalidDwellSeconds;
  }

  const hasNonBooleanValue = findNonBooleanValue(features, GeomobyPropertiesValues.showAlert);
  if (hasNonBooleanValue) {
    setDirtySave({
      isDirty: true,
      issue: `The value of an ${GeomobyPropertiesValues.showAlert} property must be true or false.`,
    });
    return hasNonBooleanValue;
  }

  const hasInvalidAlertStyle = findInvalidAlertStyleValue(features);
  if (hasInvalidAlertStyle) {
    setDirtySave({
      isDirty: true,
      issue: `The value of an ${GeomobyPropertiesValues.alertStyle} property must be 'error', 'info' or 'warn'.`,
    });
    return hasInvalidAlertStyle;
  }
  return undefined;
}
