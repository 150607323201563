import { AxiosError, AxiosResponse } from 'axios';
import { EditorSaveType } from '../Components/Map/types';
import { EntityType } from './enums';

// Should in any other cases arise with inproper handling of errors, we should use this function.
export const normaliseErrorMessage = (error: AxiosError, saveType: EditorSaveType): string => {
  const errorData = ((error as AxiosError).response as AxiosResponse)?.data;
  if (
    errorData?.message?.statusCode === 409 &&
    (saveType === EntityType.Geofence || saveType === EntityType.Layer)
  ) {
    return `Error: Make sure your Geofence and Group names are unique.`;
  } else {
    return errorData
      ? errorData.message?.message ??
          (Array.isArray(errorData.message) ? errorData.message?.[0] : errorData.message)
      : 'Error';
  }
};
